.upload__page {
  height: 100vh;
  padding-bottom: 0.01rem;
  font-family: "Montserrat", sans-serif;
  position: relative;
  background: #f4f4fd;
  margin: -8px;
}

.upload__header {
  min-height: 80px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f2f2f2;
  background-color: #ffffff;
}

.upload__header__bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 95%;
}

.clinicName {
  color: #4f4f4f;
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  gap: 1rem;
}

.clinics_dropdown .MuiOutlinedInput-input {
  padding: 0.8rem !important;
  padding-right: 3rem !important;
}

.upload__header__logo {
  width: 165px;
}

.upload__header__bar ul {
  display: inline-flex;
  gap: 2rem;
  margin: 0;
  font-size: 1.125rem;
  font-weight: 500;
  color: #5f6368;
  list-style-type: none;
}
.upload__header__bar ul li a {
  color: #5f6368;
  text-decoration: none;
}
.upload__header__bar ul li a:hover {
  color: #2b2b2c;
}

.upload__main {
  margin: 2rem auto;
  max-width: 840px;
  /* max-height: 480px; */
  background-color: #ffffff;
  /* padding: 1rem; */
  border-radius: 0.6rem;
}

.upload__section {
  padding: 2rem 7rem;
  /* background-color: red; */
}

.uploadCsvDialog__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  color: #3c4257;
  padding: 0 2rem;
}

.upload_drag_area {
  padding: 3rem 2rem !important;
}

.icon {
  padding: 1rem;
  height: 5rem;
  /* width: 2rem; */
}
.iconxl {
  padding: 2rem;
  height: 4.5rem;
}

.uploadCsvDialog__uploadArea {
  padding: 1rem 2rem 1rem;
}

.upload_instruction {
  color: #3c4257;
  font-weight: 500;
  font-size: 1.1rem;
}

.uploadCsvDialog__dashedArea {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  justify-content: center;
  align-items: center;
  border: 2px dashed #e5e5e5;
  padding: 1.5rem;
  background: rgba(208, 239, 239, 0.27);
  border-radius: 6px;
}

.uploadCsvDialog__btnWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem 1rem 2rem;
  align-items: center;
}

.fileList {
  display: flex;
  gap: 0.5rem;
  padding: 1rem 0 0 0;
  flex-wrap: wrap;
}

.fileList__item {
  display: flex;
  padding: 0.6rem;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  align-items: center;
}
.fileList__item img {
  cursor: pointer;
}

.fileList__itemWrapper {
  display: flex;
}

.uploadCsvDialog__dashedArea .link--text {
  font-weight: 600;
  font-size: 1.1rem;
  color: var(--Secondary-green--dark);
}

.upload_submitBtn {
  color: white !important;
}
.upload_logoutBtn {
  color: white !important;
  height: 2.7rem;
}
.success_dialog_container {
  font-family: "Inter", sans-serif;
}

.success_dialog {
  display: flex;
  justify-content: center;
}

.success_heading_wrap {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.success_heading {
  display: flex;
  justify-content: center;
  color: #139696;
  font-size: 24px;
  font-weight: 500;
}

.success_content {
  display: flex;
  justify-content: center;
  color: #3c4257;
  font-size: 1rem;
}

.success_buttons {
  display: flex;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
}

.export_btn {
  color: white !important;
}

.tick_icon {
  padding-left: 0.6rem;
  padding-top: 0.15rem;
}

.progressBar {
  font-family: "Inter", sans-serif;
}

.stepper {
  padding-top: 4rem !important;
}

.stepper_button_wrapper {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 4rem 1rem 1rem;
}

/* .toggleBtn_div {
  padding: 0 2rem;
}
.toggleBtn .MuiToggleButton-root {
  color: #14a6a6;
  background-color: #fff;
  padding: 0 10px;
}

.toggleBtn .MuiToggleButton-root.Mui-selected {
  color: #fff;
  background-color: #14a6a6;
}
.customText {
  text-transform: capitalize; 
} */

.toggleBtn_div {
  padding: 0 2rem;
  width: 150px;
}
